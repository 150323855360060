@use '@angular/material' as mat;
@use 'sass:map';

@import '@angular/material/theming';
@import 'apps/client/src/styles/palette';

$webclient-primary: mat.define-palette($mat-webclient-primary);
$webclient-accent: mat.define-palette(mat.$red-palette, A200, A100, A400);
$webclient-warn: mat.define-palette(mat.$orange-palette);
$webclient-success: mat.define-palette($mat-green);
$webclient-error: mat.define-palette($mat-red);
$webclient-note: mat.define-palette($mat-light-blue);

$primary-color: mat.get-color-from-palette($webclient-primary);
$accent-color: mat.get-color-from-palette($webclient-accent);
$warn-color: mat.get-color-from-palette($webclient-warn);
$success-color: mat.get-color-from-palette($webclient-success);
$error-color: mat.get-color-from-palette($webclient-error);
$note-color: mat.get-color-from-palette($webclient-note);

$border-radius-size: 10px;
$border-radius-button-size: $border-radius-size;

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$webclient-theme: mat.define-dark-theme(
  (
    color: (
      primary: $webclient-primary,
      accent: $webclient-accent,
      warn: $webclient-warn,
    ),
  )
);

$config: mat.get-color-config($webclient-theme);
$foreground: map.get($config, foreground);
$background: map.get($config, background);
$is-dark-theme: map.get($config, is-dark);
$background-color: mat.get-color-from-palette($background, card);
$outline-color: mat.get-color-from-palette(
  $foreground,
  divider,
  if($is-dark-theme, 0.3, 0.12)
);
$outline-color-op001: mat.get-color-from-palette(
  $foreground,
  divider,
  if($is-dark-theme, 0.01, 0.12)
);
$outline-color-op002: mat.get-color-from-palette(
  $foreground,
  divider,
  if($is-dark-theme, 0.02, 0.12)
);
$outline-color-op005: mat.get-color-from-palette(
  $foreground,
  divider,
  if($is-dark-theme, 0.05, 0.12)
);

$disabled-color: rgba(255, 255, 255, 0.15);
