@use '@angular/material' as mat;

@import '../styles/variables';

$mat-column-id-width: 30px;
$mat-column-actions-width: 120px;
$mat-column-name-padding-left: 48px;
$mat-column-parent-name-margin-left: 30px;

.webclient-entity-list,
.webclient-entity-nested-list {
  mat-table {
    &.entity-list {
      .mat-column-id {
        justify-content: left !important;
        padding-left: 20px;
      }

      [class*='mat-column-'] {
        justify-content: center;
      }

      .mat-column-actions,
      .mat-column-parent-actions {
        flex: 0 0 $mat-column-actions-width;
      }

      .mat-column-actions {
        div {
          width: 100%;
          text-align: end;
        }
      }
    }
  }
}
