// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'sass:map';

// Plus imports for other components in your app.
@import 'styles/variables';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($webclient-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

@import 'styles/trick';
@import 'styles/helpers';
@import 'styles/admin-list';
@import 'styles/admin-form';

$color-config: mat.get-color-config($webclient-theme);

@import 'angular-calendar/scss/angular-calendar.scss';

$text-color: #d5d6d7;
$bg-dark-primary-color: #1f262d;
$bg-dark-secondary-color: #394046;
$bg-active-color: #2c343a;
$border-color: rgba(0, 0, 0, 0.6);
@include cal-theme(
  (
    bg-primary: mat.get-color-from-palette($webclient-primary),
    bg-secondary: $bg-dark-secondary-color,
    weekend-color: indianred,
    bg-active: $bg-active-color,
    border-color: $border-color,
    gray: $bg-dark-secondary-color,
    today-bg: $bg-dark-secondary-color,
    event-color-primary: $bg-dark-secondary-color,
  )
);
