form {
  &.admin-form {
    margin-top: 30px !important;

    fieldset {
      padding-top: 20px;
      border-color: $outline-color-op005;
      margin-bottom: 30px;
      border-radius: $border-radius-size;
    }
  }
}
